<template>
  <div class="row no-gutters p-3 text-white pulse-daily-summary">
    <div class="col-3 font-17 font-weight-strong line-height-2">
      Your activity for today
    </div>
    <div class="col-7 summary-col">
      <div class="row">
        <div class="col-3 d-flex flex-column align-items-center pt-3">
          <div class="summary-icon bg-blue">
            <i class="sdicon-home font-21" />
          </div>
          <div class="summary-text">
            <span class="font-weight-bold">{{ summaryData.active_properties_count }}</span>
            Active Properties
          </div>
        </div>
        <div class="col-3 d-flex flex-column  align-items-center pt-3">
          <div class="summary-icon bg-purple">
            <i class="sdicon-magnifying-glass" />
          </div>
          <div class="summary-text">
            <span class="font-weight-bold">{{ summaryData.new_inquiries_count }}</span>
            New {{ summaryData.new_inquiries_count === 1 ? 'Inquiry' : 'Inquiries' }}
          </div>
        </div>
        <div class="col-3 d-flex flex-column  align-items-center pt-3">
          <div class="summary-icon bg-green">
            <i class="sdicon-calendar-plus" />
          </div>
          <div class="summary-text">
            <span class="font-weight-bold">{{ summaryData.daily_schedules_count }}</span>
            Planned {{ summaryData.daily_schedules_count === 1 ? 'tour' : 'tours' }} today
          </div>
        </div>
        <div class="col-3 d-flex flex-column align-items-center pt-3">
          <div class="summary-icon bg-orange">
            <i class="sdicon-calendar-checked" />
          </div>
          <div class="summary-text">
            <span class="font-weight-bold">{{ summaryData.completed_schedules_count }}</span>
            Completed Showings
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 summary-col d-flex justify-content-end">
      <div
        class="reload-btn"
        @click="$emit('reload')"
      >
        <i class="sdicon-reload font-15" />
      </div>
    </div>
  </div>
</template>

<script>
import {} from '@vue/composition-api';

export default {
  name: 'PulseDailySummary',
  components: {},
  props: {
    data: {
      required: true,
      type: Object,
    },
    isDummy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const summaryData = Object.entries(props.data).reduce((newObj, [key, val]) => {
      if (!Number.isNaN(val)) {
        newObj[key] = props.isDummy ? '-' : val;
      }
      return newObj;
    }, {});
    return {
      summaryData,
    };
  },
};
</script>

<style lang="scss" scoped>
    .pulse-daily-summary {
        position: absolute;
        top: 1rem;
        left:1.75rem;
        width:calc(100% - 3.5rem);
        height: 4rem;
        background-color: fade_out(gray-color('darker'), 0.13);
        border-radius: $app-border-radius;
        z-index: 1000;
        .summary-icon{
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            font-size: $--font-size-medium;
        }
        .summary-text{
            margin-top: 4px;
            font-size: 11px;
        }
        .reload-btn{
            cursor: pointer;
            border-radius: $app-border-radius;
            border: 1px solid gray-color('light');
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 35px;
        }
    }
</style>
