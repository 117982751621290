<template>
  <div v-loading="metricsRef===null">
    <div class="row justify-content-between">
      <div class="col-6 text-gray-darker font-weight-strong font-17 card-title">
        Business metrics
      </div>
      <div class="col d-flex justify-content-end">
        <SdDaysRangeSelect
          :disabled="isDummy"
          @change="updateRange"
        />
      </div>
    </div>
    <div
      v-if="metricsRef!==null"
      class="row mt-4 pl-2 pr-2"
    >
      <div class="col-4 metric border-right border-bottom">
        <div>{{ metricsRef.average_time_to_deactivate }}</div>
        <span>Avg. days to rent</span>
      </div>
      <div class="col-4 metric border-right border-bottom">
        <div>{{ metricsRef.average_tours_to_deactivate }}</div>
        <span>Avg. tours to rent</span>
      </div>
      <div class="col-4 metric border-bottom">
        <div>{{ metricsRef.inquiries_count }}</div>
        <span>Inquiries</span>
      </div>
      <div class="col-4 metric border-right">
        <div>{{ metricsRef.leads_toured_count }}</div>
        <span>Prospects toured</span>
      </div>
      <div class="col-4 metric border-right">
        <div>{{ metricsRef.completed_tours_count }}</div>
        <span>Tours completed</span>
      </div>
      <div class="col-4 metric">
        <div>{{ metricsRef.rented_units_count }}</div>
        <span>Rented units</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';

export default {
  name: 'PulseBusinessMetrics',
  components: {},
  props: {
    isDummy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const daysRange = reactive({ from: null, to: null });
    const metricsRef = ref(null);

    return {
      metricsRef,
      updateRange,
    };

    async function loadData() {
      metricsRef.value = null;
      let data = {};
      if (props.isDummy) {
        data = {
          average_time_to_deactivate: '-',
          average_tours_to_deactivate: '-',
          inquiries_count: '-',
          leads_toured_count: '-',
          completed_tours_count: '-',
          rented_units_count: '-',
        };
        metricsRef.value = data;
      } else {
        data = await dispatch('Business/dashboardMetrics', { from: daysRange.from, to: daysRange.to });
        metricsRef.value = formatData(data);
      }
    }

    function updateRange(args) {
      daysRange.from = args.from;
      daysRange.to = args.to;
      loadData();
    }

    function formatData(data) {
      for (const key in data) {
        data[key] = parseFloat(data[key].toFixed(1));
      }
      return data;
    }
  },
};
</script>

<style lang="scss" scoped>
    .metric {
        border-color: gray-color('light');
        border-style: solid;
        border-width: 0;
        text-align: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        div{
            font-size: 2.25rem;
            line-height: 2.5rem;
            height: 3rem;
            color: gray-color('darker');
            font-weight: $strong;
        }
        span {
            display: block;
            color: gray-color('dark');
            font-weight: $strong;
            font-size: 14px;
            line-height: 16px;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
        }
    }
</style>
