<template>
  <SdPage id="sd-pulse-page">
    <SdPageHeader
      class="row mt-2"
      :title="personalTitle"
    />
    <div class="row mt-5">
      <PulseToursMap
        class="col-12 position-relative w-100 rounded overflow-hidden pt-0"
        :is-dummy="isBasicPlan"
      />
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xl-4">
        <PulseBusinessMetrics
          class="pulse-card"
          :is-dummy="isBasicPlan"
        />
      </div>
      <div class="col-12 col-xl-4">
        <PulseLeaderBoard
          class="pulse-card"
          :is-dummy="isBasicPlan"
        />
      </div>
      <div class="col-12 col-xl-4">
        <PulseLikelyToApply
          class="pulse-card"
          :is-dummy="isBasicPlan"
        />
      </div>
    </div>
  </SdPage>
</template>

<script>
import Plan from '@/constants/Plan';
import { greeting } from '../../utils/DatetimeUtil';
import PulseToursMap from '../../components/pulse/PulseToursMap';
import PulseBusinessMetrics from '../../components/pulse/PulseBusinessMetrics';
import PulseLeaderBoard from '../../components/pulse/PulseLeaderBoard';
import PulseLikelyToApply from '../../components/pulse/PulseLikelyToApply';

export default {
  name: 'Pulse',
  components: {
    PulseLikelyToApply, PulseLeaderBoard, PulseBusinessMetrics, PulseToursMap,
  },
  setup(props, context) {
    const store = context.root.$store;
    const plan = store.getters['Auth/plan'];
    const isBasicPlan = plan.name === Plan.BASIC;
    const user = store.state.Auth.user;
    const personalTitle = `${greeting()} ${user.first_name},`;
    return {
      personalTitle,
      isBasicPlan,
    };
  },
};
</script>

<style lang="scss">
    .pulse-card {
        background: $white;
        border-radius: $app-border-radius;
        min-height: 315px;
        padding: 1rem;
        overflow: hidden;
        .card-title {
            line-height: 2.25rem;
        }
        .card-date-range {
            width: 142px;
        }
        .pulse-table {
            &::before{
                height: 0;
            }
            thead {
                font-size: 14px;
                th {
                    padding: 0.25rem 0;
                    font-weight: normal;
                    border: none !important;
                    .cell{
                        padding: 0;
                    }
                }
            }
            tbody {
                td {
                    border-color: fade_out(gray-color('light'), 0.5);
                    padding: 0.5rem 0;
                }
            }
        }
    }
</style>
