<template>
  <div v-loading="likelyRef===null">
    <div class="row justify-content-between">
      <div class="col-12 text-gray-darker font-weight-strong font-17 card-title">
        Recent prospects likely to apply
      </div>
    </div>
    <ElTable
      v-if="likelyRef!==null"
      :data="likelyRef"
      class="pulse-table pulse-likely-table mt-2"
      :class="{'dummy': isDummy}"
    >
      <ElTableColumn
        min-width="85"
        label="Name"
        prop="full_name"
        class-name="likely-name"
      >
        <template slot-scope="scope">
          <ProspectProfileLink :prospect="scope.row" />
        </template>
      </ElTableColumn>

      <ElTableColumn
        min-width="155"
        label="Property"
        prop="address"
        class-name="likely-property"
      />
      <ElTableColumn
        min-width="55"
        label="Tour ID"
        class-name="likely-tour-id"
      >
        <template slot-scope="scope">
          <router-link
            target="_blank"
            :to="{name:'app.tours.show', params:{id:scope.row.schedule_id}}"
          >
            #{{ scope.row.schedule_id }}
          </router-link>
        </template>
      </ElTableColumn>
      <ElTableColumn
        min-width="35"
        class-name="likely-phone"
      >
        <template slot-scope="scope">
          <ElTooltip :content="scope.row.phone">
            <a
              target="_blank"
              class="phone-tag"
              :href="`tel:${scope.row.phone}`"
            >
              <i class="sdicon-phone-fill" />
            </a>
          </ElTooltip>
        </template>
      </ElTableColumn>
    </ElTable>
    <template v-if="isDummy">
      <div
        v-for="(n, key) in 5"
        :key="key"
        class="likely-dummy-row d-flex justify-content-start"
      >
        <span
          class="main-row"
        />
        <span class="phone">
          <img src="@/assets/phone.svg">
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import momentUtil from '../../utils/MomentUtil';
import { shortenName } from '../../utils/StringUtil';
import ProspectProfileLink from '../prospect/ProspectProfileLink.vue';

export default {
  name: 'PulseLikelyToApply',
  components: { ProspectProfileLink },
  props: {
    isDummy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const likelyRef = ref(null);
    loadData();
    return {
      likelyRef,
    };
    async function loadData() {
      likelyRef.value = null;
      if (!props.isDummy) {
        const data = await dispatch('Business/dashboardLikelyToApply');
        likelyRef.value = formatData(data);
      } else {
        likelyRef.value = [];
      }
    }

    function formatData(data) {
      return data
        .map((row) => {
          const full_name = shortenName(row.lead.full_name);
          const tour_at = momentUtil(row.tour_at, null);
          return {
            lead_id: row.lead.id,
            address: row.property.address.name,
            schedule_id: row.schedule_id,
            phone: row.lead.formatted_phone_number,
            full_name,
            tour_at,
          };
        }).sort((a, b) => b.tour_at - a.tour_at);
    }
  },
};
</script>

<style lang="scss">
    .dummy {
      .el-table__empty-block {
        display: none;
      }
    }
    .likely-dummy-row {
      border-radius: 2px;
      margin-bottom: 12px;
      .main-row {
        height: 29px;
        background: theme-color(light-grey);
        width: 100%;
        display: inline-block;
      }
      .phone {
        display: none;
      }
      @media (min-width: 540px) {
        .phone {
          display: inline-block;
          margin: 3px;
          margin-left: 22px;
        }
      }
    }
    .pulse-likely-table{
        tbody {
            td{
                .cell {
                    padding: 0;
                    font-size: $--font-size-small;
                    line-height: 1.1rem;
                }
                &.likely-property {
                    color: gray-color('dark');
                }
                &.likely-name {
                    .cell {
                        line-height: 24px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                &.likely-phone{
                    .phone-tag {
                        display: block;
                        width: 1.5rem;
                        height: 1.5rem;
                        line-height: 1.5rem;
                        background-color: theme-color('primary-light');
                        color: $white;
                        text-align: center;
                        font-size: 9px;
                        cursor: pointer;
                        border-radius: $app-border-radius;
                    }
                }
            }
        }
    }
</style>
