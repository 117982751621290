<template>
  <div v-if="tourData">
    <div class="property-image">
      <img :src="tourData.property.picture_url || propertyPlaceholderPic">
    </div>
    <div class="row pl-3 pr-3 pb-3">
      <div class="col-12 text-gray-darker font-weight-strong mt-3 mb-0 font-17">
        {{ tourData.property.short_address }}
      </div>
      <div class="col-4  pt-3">
        <div class="font-13 text-gray-darker font-weight-strong">
          {{ tourData.time_in_market }}
        </div>
        <div class="font-12 mt-1 text-gray-dark ">
          Days active
        </div>
      </div>
      <div class="col-4 pt-3">
        <div class="font-13 text-gray-darker font-weight-strong">
          {{ tourData.inquiries_count }}
        </div>
        <div class="font-12 mt-1 text-gray-dark">
          Inquiries
        </div>
      </div>
      <div class="col-4  pt-3">
        <div class="font-13 text-gray-darker font-weight-strong">
          {{ tourData.leads_toured_count }}
        </div>
        <div class="font-12 mt-1 text-gray-dark">
          Leads toured
        </div>
      </div>
      <template v-if="todaysTours">
        <div class="col-12 text-gray-darker font-weight-strong font-15 pt-3">
          Today's Tours
        </div>
        <ul class="tours-list">
          <li
            v-for="schedule in todaysTours"
            :key="schedule.id"
          >
            {{ schedule.text }}
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import get from 'lodash.get';
import propertyPlaceholderPic from '@/assets/property-empty-state.svg';

import momentUtil from '../../utils/MomentUtil';

export default {
  name: 'PulseTourMapPopup',
  components: {},
  props: {
    tourData: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const todaysTours = computed(computeTodaysTours);
    return {
      propertyPlaceholderPic,
      todaysTours,
    };

    function computeTodaysTours() {
      const schedules = props.tourData.schedules;
      if (!schedules.length) {
        return null;
      }
      const timezone = props.tourData.property.timezone;
      return schedules.map((schedule) => {
        const tourLeads = schedule.schedule_inquiries.map((scheduleInquiry) => get(scheduleInquiry, 'inquiry.lead.full_name', ''));
        const tourLeadNames = ` by ${tourLeads.join(', ')}`;
        return {
          id: schedule.id,
          text: momentUtil(schedule.tour_at, timezone).toDisplayShortTimeFormat() + tourLeadNames,
        };
      });
    }
  },
};
</script>

<style lang="scss" scoped>
    .property-image {
        overflow: hidden;
        height: 131px;
        img{
            width: 100%;
        }
    }
    .tours-list {
        margin-top: 1rem;
        margin-bottom: 0;
        list-style: none;
        li {
            line-height: $--font-size-medium;
            margin-bottom: 0.25rem;
            font-size: $--font-size-base;
            &::before {
                content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: theme-color('primary');
                display: inline-block;
                width: 1em;
                margin-left: -1.5em;
                font-weight: bold;
                font-size: $--font-size-medium;
            }
        }
    }
</style>
