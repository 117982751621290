<template>
  <div class="row no-gutters p-3 text-white pulse-unavailable">
    <div class="holder">
      <img
        class="image"
        src="@/assets/unavailable-property.svg"
      >
      <div class="title">
        Pulse is available in our Standard plan
      </div>
      <div class="subtext">
        <RouterLink
          :to="{ name: 'app.billing', query: {modify:true}}"
          target="_blank"
        >
          Learn more
        </RouterLink> about our plan
      </div>
    </div>
  </div>
</template>

<script>
import {} from '@vue/composition-api';

export default {
  name: 'PulseUnavailable',
  setup(props, context) {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.pulse-unavailable {
  position: absolute;
  top: 11rem;
  left: calc(50% - 15rem);
  width: 30rem;
  height: 15rem;
  background-color: $white;
  z-index: 1000;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  .holder {
    text-align: center;
    width: 280px;
    margin: 0 auto;
    .image {
      margin: 18px auto;
    }
    .title {
      color: gray-color(darker);
      font-weight: $strong;
      font-size: $--font-size-base;
      line-height: 28px;
      text-align: center;
    }
    .subtext {
      margin-top: 3px;
      color: gray-color(darker);
      font-size: $--font-size-small;
      line-height: 20px;
      text-align: center;
    }
  }
}
</style>
