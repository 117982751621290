<template>
  <div v-loading="!isDummy && leaderboardRef===null">
    <div class="row justify-content-between">
      <div class="col-6 text-gray-darker font-weight-strong font-17 card-title">
        Leaderboard
      </div>
      <div class="col d-flex justify-content-end">
        <SdDaysRangeSelect
          :disabled="isDummy"
          @change="updateRange"
        />
      </div>
    </div>
    <ElTable
      v-if="isDummy || leaderboardRef !== null"
      :data="leaderboardRef"
      class="pulse-table pulse-leaderboard-table mt-2"
      :class="{'dummy': isDummy}"
    >
      <ElTableColumn
        min-width="115"
        label="Name"
        class-name="leaderboard-name"
      >
        <template slot-scope="scope">
          <img
            v-if="scope.row.avatar"
            class="rounded-circle"
            :src="scope.row.avatar"
          >
          <span class="font-13 text-gray-darker text-capitalize">{{ scope.row.short_name }}</span>
        </template>
      </ElTableColumn>
      <ElTableColumn
        min-width="75"
        label="Total tours"
        prop="completed_tours_count"
        class-name="leaderboard-number"
      />
      <ElTableColumn
        min-width="55"
        label="Leased"
        prop="leased_count"
        class-name="leaderboard-number"
      />
      <ElTableColumn
        min-width="70"
        label="Conversion"
        prop="completed_tours_leased_rate"
        class-name="leaderboard-number"
      />
    </ElTable>
    <template v-if="isDummy">
      <div
        v-for="(n, key) in 5"
        :key="key"
        class="leader-dummy-row"
      >
        <span
          class="main-row"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';

import icon from '../../assets/icon.svg';
import { shortenName } from '../../utils/StringUtil';

export default {
  name: 'PulseLeaderBoard',
  components: {},
    props: {
    isDummy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const daysRange = reactive({ from: null, to: null });
    const leaderboardRef = ref(null);

    return {
      leaderboardRef,
      updateRange,
    };

    async function loadData() {
      leaderboardRef.value = null;
      let data = [];
      if (!props.isDummy) {
        data = await dispatch('Business/dashboardLeaderboard', { from: daysRange.from, to: daysRange.to });
      }
      leaderboardRef.value = formatData(data);
    }

    function updateRange(args) {
      daysRange.from = args.from;
      daysRange.to = args.to;
      loadData();
    }

    function formatData(data) {
      return data
        .sort((a, b) => b.completed_tours_count - a.completed_tours_count)
        .slice(0, 5)
        .map((agent) => {
          const short_name = shortenName(agent.full_name);
          const completed_tours_leased_rate = `${parseFloat(agent.completed_tours_leased_rate.toFixed(0))}%`;
          const avatar = agent.full_name === 'showdigs' ? icon : agent.avatar_file_url;
          return {
            ...agent,
            short_name,
            completed_tours_leased_rate,
            avatar,
          };
        });
    }
  },
};
</script>

<style lang="scss">
    .dummy {
      .el-table__empty-block {
        display: none;
      }
    }
    .leader-dummy-row {
      border-radius: 2px;
      margin-bottom: 6px;
      .main-row {
        height: 29px;
        background: theme-color(light-grey);
        width: 100%;
        display: inline-block;
      }
    }
    .pulse-leaderboard-table{
        tbody {
            td{
                &.leaderboard-number {
                    color: gray-color('dark');
                    font-size: $--font-size-base;
                    line-height: 1.5rem;
                }
                &.leaderboard-name {
                    .cell {
                        padding: 0;
                        display: flex;
                    }
                    img {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                    span{
                        margin-left: 5px;
                        line-height: 1.5rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
</style>
