<template>
  <div v-loading="!mapDataRef">
    <PulseDailySummary
      v-if="mapDataRef"
      :data="mapDataRef"
      :is-dummy="isDummy"
      @reload="loadData"
    />
    <PulseUnavailable
      v-if="isBasicPlan"
    />
    <GmapMap
      ref="pulseMapRef"
      class="pulse-tours-map"
      :zoom="5"
      :center="{lat:42.861, lng:-93.370}"
      :options="defaultMapOptions"
      map-type-id="roadmap"
    >
      <GmapInfoWindow
        :options="defaultPopupOptions"
        :position="tourPopup.position"
        :opened="tourPopup.show"
        @closeclick="tourPopup.show = false"
      >
        <PulseTourMapPopup
          v-if="tourPopup.tourData"
          :tour-data="tourPopup.tourData"
        />
      </GmapInfoWindow>
      <template v-if="mapDataRef && mapDataRef.units && mapDataRef.units.length">
        <GmapMarker
          v-for="(unit, index) in mapDataRef.units"
          :key="index"
          :position="unit.latLng"
          :options="unit.markerOptions"
          :clickable="true"
          @click="markerClicked(unit, index)"
        />
      </template>
    </GmapMap>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from '@vue/composition-api';
import Plan from '@/constants/Plan';
import mapBlueMarker from '@/assets/map-blue-marker.svg';
import mapGreenMarker from '@/assets/map-green-marker.svg';
import mapOrangeMarker from '@/assets/map-orange-marker.svg';
// styles are editable here: https://mapstyle.withgoogle.com/
import mapStyles from '@/assets/googleMapStyles.json';

import momentUtil from '../../utils/MomentUtil';

import PulseTourMapPopup from './PulseTourMapPopup';
import PulseDailySummary from './PulseDailySummary';
import PulseUnavailable from './PulseUnavailable';

const defaultMapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUI: false,
  styles: mapStyles,
};
const defaultPopupOptions = {
  pixelOffset: {
    width: 0,
    height: -35,
  },
};
const defaultMarkerOptions = {
  icon: { url: mapBlueMarker },
};
/* eslint-disable no-undef */
export default {
  name: 'PulseToursMap',
  components: { PulseTourMapPopup, PulseDailySummary, PulseUnavailable },
    props: {
    isDummy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const plan = store.getters['Auth/plan'];
    const isBasicPlan = plan.name === Plan.BASIC;
    const dispatch = store.dispatch;
    const mapDataRef = ref(null);
    const nowUTC = ref(momentUtil(null, null));
    const tourPopup = reactive({
      show: false,
      currentIndex: null,
      position: {},
      tourData: null,
    });

    onMounted(() => {
      context.root.$nextTick(() => {
        initMap();
      });
    });

    return {
      defaultMapOptions,
      defaultPopupOptions,
      loadData,
      mapDataRef,
      markerClicked,
      tourPopup,
      google,
      isBasicPlan,
    };

    async function initMap() {
      await window.vueGoogleMapsInit(google);
      await loadData();
      context.refs.pulseMapRef.$mapPromise.then((map) => {
        map.fitBounds(mapDataRef.value.bounds, 20);
        // close open popup if map is clicked
        google.maps.event.addListener(map, 'click', () => {
          tourPopup.show = false;
        });
      });
    }

    async function loadData() {
      mapDataRef.value = null;
      const data = await dispatch('Business/dashboardMap');
      nowUTC.value = momentUtil(null, null);
      mapDataRef.value = formatMapData(data);
    }

    function formatMapData(mapData) {
      mapData.bounds = new google.maps.LatLngBounds();
      if (mapData.units && mapData.units.length) {
        mapData.units.forEach((unit) => {
          const cords = unit.property.location.coordinates;
          unit.latLng = new google.maps.LatLng(cords[1], cords[0]);
          unit.markerOptions = resolveUnitMarkerOptions(unit);
          mapData.bounds.extend(unit.latLng);
          return unit;
        });
        if (isBasicPlan) {
          mapData.units = [];
        }
      }
      return mapData;
    }

    function markerClicked(marker, index) {
      tourPopup.tourData = marker;
      tourPopup.position = marker.latLng.toJSON();
      if (tourPopup.currentIndex === index) {
        tourPopup.show = !tourPopup.show;
      } else {
        tourPopup.show = true;
        tourPopup.currentIndex = index;
      }
    }

    function resolveUnitMarkerOptions(unit) {
      const options = {
        icon: { ...defaultMarkerOptions.icon },
      };
      if (unit.schedules && unit.schedules.length) {
        options.icon.url = mapGreenMarker;
        const futureSchedules = unit.schedules.filter((schedule) => momentUtil(schedule.tour_at, null).isAfter(nowUTC));
        if (futureSchedules.length === 0) {
          options.icon.url = mapOrangeMarker;
        }
      }
      return options;
    }
  },
};
</script>

<style lang="scss">
    .pulse-tours-map {
        width: 100%;
        height: 512px;
        .gm-style .gm-style-iw-c{
            border-radius: 4px;
            padding: 0;
            width: 246px;
            box-shadow: none;
            .gm-ui-hover-effect {
                visibility: hidden;
            }
        }
        .gm-style-iw-d {
            overflow-x: hidden !important;
            overflow-y: auto   !important;
        }
    }
</style>
